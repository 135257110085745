import { useMutation } from '@apollo/client';
import { UPDATE_USER_SETTINGS } from '../../graphql/settings/settings.mutations';
import {
  DataError,
  Mutation,
  UpdateSettingsLocations,
  UserSettingsInput,
  UserSettingsOutput,
} from '../generated/graphql';
import { IMutationResponse } from '../types';

interface UpdateUserSettingsResponse
  extends IMutationResponse<UserSettingsOutput> {
  onUpdateUserSettings: (
    data: UserSettingsInput,
    location: UpdateSettingsLocations
  ) => Promise<UserSettingsOutput | null>;
  responseErrors: DataError[] | null;
}

export const useUpdateUserSettings = (): UpdateUserSettingsResponse => {
  const [updateUserSettings, { data, error, loading }] =
    useMutation<Pick<Mutation, 'updateUserSettings'>>(UPDATE_USER_SETTINGS);

  const onUpdateUserSettings = async (
    data: UserSettingsInput,
    location: UpdateSettingsLocations
  ) => {
    try {
      const result = await updateUserSettings({
        variables: { userSettings: data, sourceLocation: location },
      });

      return result.data?.updateUserSettings.data ?? null;
    } catch (error) {
      throw error;
    }
  };
  const convertedErrors =
    data?.updateUserSettings?.errors?.map((error) => ({
      code: error?.code,
      description: error?.description ?? '',
      message: error?.message ?? '',
    })) ?? null;
  return {
    onUpdateUserSettings,
    data: data?.updateUserSettings.data ?? null,
    responseErrors: convertedErrors,
    loading,
    error,
  };
};
