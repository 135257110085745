export const callNumber = (
  isOnlyOne: boolean,
  phoneNumber: string,
  showQR: boolean,
  setShowQR: () => void,
  toggle: () => void,
  isMobile: boolean
) => {
  if (isMobile && isOnlyOne) {
    window.location.href = `tel:${phoneNumber}`;
  } else if (!isMobile && isOnlyOne && !showQR) {
    setShowQR();
  } else {
    toggle();
  }
};
