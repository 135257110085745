import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import {
  CONNECTION_FLIGHT_TYPE,
  INBOUND,
  OUTBOUND,
} from '../../utils/constants';

interface IConnectionButtons {
  textState: string;
  handlerButton: (connectionType: CONNECTION_FLIGHT_TYPE) => void;
}

const ConnectionButtons = ({
  textState,
  handlerButton,
}: IConnectionButtons) => {
  const inOutboundButtonsClass =
    'flex flex-row p-[30px] mobile:p-[25px] w-[55%] mobile:w-full';

  const defaultClassNames = classNames(
    'font-head-bold text-primary text-14 leading-[18px] border-1 border-primary w-full p-8 flex justify-center dark:text-grey-12 dark:border-grey-12 text-center items-center'
  );
  const selectedClassNames =
    'bg-primary text-white1 dark:bg-grey-12 dark:text-grey-90';
  const notSelectedClassNames = 'bg-white dark:bg-grey-90';

  const optionClassNames = 'appearance-none hidden';

  const getLabelClassNames = (option: string, extraClassNames: string) => {
    return classNames(defaultClassNames, extraClassNames, {
      [selectedClassNames]: option === textState,
      [notSelectedClassNames]: option !== textState,
    });
  };

  return (
    <div className={inOutboundButtonsClass}>
      <input
        type="radio"
        name="timeZone"
        value={INBOUND.CONNECTIONS}
        id={INBOUND.CONNECTIONS}
        className={optionClassNames}
        checked={textState === INBOUND.CONNECTIONS}
        onChange={() => handlerButton(CONNECTION_FLIGHT_TYPE.INBOUND)}
      />
      <label
        htmlFor={INBOUND.CONNECTIONS}
        className={getLabelClassNames(INBOUND.CONNECTIONS, 'rounded-l-4')}>
        {INBOUND.CONNECTIONS}
      </label>

      <input
        type="radio"
        name="timeZone"
        value={OUTBOUND.CONNECTIONS}
        id={OUTBOUND.CONNECTIONS}
        className={optionClassNames}
        checked={textState === OUTBOUND.CONNECTIONS}
        onChange={() => handlerButton(CONNECTION_FLIGHT_TYPE.OUTBOUND)}
      />
      <label
        htmlFor={OUTBOUND.CONNECTIONS}
        className={getLabelClassNames(OUTBOUND.CONNECTIONS, 'rounded-r-4')}>
        {OUTBOUND.CONNECTIONS}
      </label>
    </div>
  );
};

export default ConnectionButtons;
