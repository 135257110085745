import { useDispatch } from 'react-redux';
import { useMarkNotificationAsRead } from './useMarkNotificaitonAsRead';
import {
  IUserNotification,
  readFlightNotification,
} from '../../redux/reducers/newsfeedReducer';

export const useReadNotifications = () => {
  const { markAsRead } = useMarkNotificationAsRead();
  const dispatch = useDispatch();

  return (notifications: IUserNotification[], flightId: string | undefined) => {
    const ids = notifications?.[flightId]
      ?.filter((f) => !f.read)
      .map((f) => f.notification._id);

    try {
      if (flightId && ids) {
        dispatch(readFlightNotification(flightId));
        markAsRead(ids);
      }
    } catch (err) {}
  };
};
