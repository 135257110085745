import React, { useState } from 'react';
import classNames from 'classnames';
import {
  CONNECTION_FLIGHT_TYPE,
  DEFAULT_FLIGHTS_SHOWN,
  EMPTY_STATE,
  INBOUND,
  INITIAL_STATE,
  OUTBOUND,
  SHOW_MORE,
} from '../../utils/constants';
import Icon from '../Icon/Icon';
import Title from '../Title/Title';
import Button from '../Button/Button';

import './ConnectionFlights.scss';
import { useSelector } from 'react-redux';
import {
  select24Format,
  selectUTC,
} from '../../redux/reducers/settingsReducer';
import { calculateTime } from '../../utils/calculateTime';
import { ITimesObject } from '../../utils/types';

const inboundItemsClass =
  'dark:text-grey-12 text-primary font-body-thin text-80 leading-[80px] h-[80px]';
const totalItemsClass =
  'font-head-light text-18 leading-[19px] text-grey-40 mobile:mb mb-[9px] ml-8';
const titleClass = 'mb-4 text-grey-40';
const subtitleClass = 'flex items-end';
const dividerClass = 'h-[1px] bg-grey-12 w-[100%]';
const titleContainer =
  'flex flex-row mobile:flex-col ml-32 mobile:ml-24 mb-32 mobile:mb-24 tablet:gap-[100px] laptop:gap-[210px] desktop:gap-[210px] mobile:gap-24';
const tableHeaderClass = 'text-12 leading-[12px] font-body-text text-grey-40';
const tableItemClass =
  'font-head-light dark:text-grey-12 text-primary text-16 leading-[21px]';
const tableContainer = 'mx-32 mobile:mx-24 mt-32 mb-[23px] mobile:mb-24';
const iconContainer = 'flex items-center';
const tableClass = 'pb-24 connection-flights-grid';
const passengerIconClass = 'fill-grey-25 mr-[7px]';
const baggageIconClass = 'fill-grey-25 mr-[10px]';
const showMoreIconClass =
  'self-center mr-[19px] dark:fill-grey-12 fill-primary';
const showMoreButtonClass =
  'flex justify-center w-[177px] self-center mb-[37px] dark:text-grey-12 text-primary';
const showMoreTextClass = 'font-head-bold leading-[18px] text-14';

interface IInOutboundFlight {
  id: string;
  Inbound?: string;
  outbound?: string;
  flight: string;
  origin: string;
  from?: string;
  to?: string;
  gate: string;
  position: string;
  arrival?: string;
  departure?: string;
  checkedInPassengers: number;
  checkedInLuggage: number;
  arrivalTimeZoneObject?: ITimesObject;
  departureTimeZoneObject?: ITimesObject;
}

interface IInOutboundFlights {
  flightsArray?: IInOutboundFlight[];
  totalPassengers?: number | string;
  totalBaggages?: number | string;
  className: string;
  isLoading: boolean;
  connectionType: string;
}

const ConnectionFlights = ({
  flightsArray = [],
  totalPassengers = INITIAL_STATE,
  totalBaggages = INITIAL_STATE,
  className,
  isLoading,
  connectionType = CONNECTION_FLIGHT_TYPE.INBOUND,
}: IInOutboundFlights) => {
  const inboundFlightsContainer = classNames(
    'mobile:w-[100%] bg-white dark:bg-grey-90 rounded-8 flex flex-col h-fit',
    className
  );
  const [rowsShown, setRowsShown] = useState(DEFAULT_FLIGHTS_SHOWN);

  const currentConnection =
    connectionType === CONNECTION_FLIGHT_TYPE.INBOUND ? INBOUND : OUTBOUND;

  const isUTC = useSelector(selectUTC);
  const is24Format = useSelector(select24Format);

  const totalInOutboundPassengers = isLoading
    ? INITIAL_STATE
    : flightsArray.reduce((prev, curr) => prev + curr.checkedInPassengers, 0);
  const totalInOutboundBaggages = isLoading
    ? INITIAL_STATE
    : flightsArray.reduce((prev, curr) => prev + curr.checkedInLuggage, 0);

  return (
    <div className={inboundFlightsContainer}>
      <div className={titleContainer}>
        <Title
          title={currentConnection?.TOTAL_PASSANGERS}
          titleColorClass={titleClass}>
          <div className={subtitleClass}>
            <p className={inboundItemsClass}>
              {totalInOutboundPassengers ?? EMPTY_STATE}
            </p>
            <p className={totalItemsClass}>{totalPassengers ?? EMPTY_STATE}</p>
          </div>
        </Title>
        <Title
          title={currentConnection?.TOTAL_BAGGAGES}
          titleColorClass={titleClass}>
          <div className={subtitleClass}>
            <p className={inboundItemsClass}>
              {totalInOutboundBaggages ?? EMPTY_STATE}
            </p>
            <p className={totalItemsClass}>{totalBaggages ?? EMPTY_STATE}</p>
          </div>
        </Title>
      </div>
      <hr className={dividerClass} />

      {!!flightsArray.length ? (
        <>
          <div className={tableContainer}>
            <div className={tableClass}>
              <p className={tableHeaderClass}>
                {currentConnection.CONNECTIONS}
              </p>
              <p className={tableHeaderClass}>
                {currentConnection.CONNECTION_ACTION}
              </p>
              <p className={tableHeaderClass}>{currentConnection.GATE}</p>
              <p className={tableHeaderClass}>{currentConnection.POSITION}</p>
              <p className={classNames(tableHeaderClass, 'col-span-3')}>
                {currentConnection.CONNECTION_DIRECTION}
              </p>
              <p className={tableHeaderClass}></p>
              {flightsArray.map((item, index) => {
                const itemArrivalTime = calculateTime(
                  isUTC,
                  is24Format,
                  connectionType === CONNECTION_FLIGHT_TYPE.INBOUND
                    ? item?.arrivalTimeZoneObject
                    : item?.departureTimeZoneObject
                );

                return index >= rowsShown ? null : (
                  <React.Fragment key={item.id}>
                    <p className={tableItemClass}>
                      {connectionType === CONNECTION_FLIGHT_TYPE.INBOUND
                        ? item?.Inbound
                        : item?.outbound}
                    </p>
                    <p className={tableItemClass}>
                      {connectionType === CONNECTION_FLIGHT_TYPE.INBOUND
                        ? item?.from
                        : item?.to}
                    </p>
                    <p className={tableItemClass}> {item.gate}</p>
                    <p className={tableItemClass}>{item.position}</p>
                    <p className={tableItemClass}>
                      {connectionType === CONNECTION_FLIGHT_TYPE.INBOUND
                        ? item?.arrival
                        : item?.departure}
                    </p>
                    <p className={tableItemClass}>{itemArrivalTime}</p>
                    <div className={iconContainer}>
                      <Icon
                        width={20}
                        height={20}
                        variant="person"
                        className={passengerIconClass}
                      />
                      <p className={tableItemClass}>
                        {item.checkedInPassengers}
                      </p>
                    </div>
                    <div className={iconContainer}>
                      <Icon variant="baggage" className={baggageIconClass} />
                      <p className={tableItemClass}>{item.checkedInLuggage}</p>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          {flightsArray.length > rowsShown && (
            <Button
              Icon={
                <Icon variant="arrowExpand" className={showMoreIconClass} />
              }
              onClick={() => setRowsShown((prev) => prev + 5)}
              text={SHOW_MORE}
              textClassName={showMoreTextClass}
              className={showMoreButtonClass}
            />
          )}
        </>
      ) : (
        <h1 className="p-24 self-center text-primary">
          {isLoading
            ? currentConnection?.CONNECTION_FLIGHTS
            : currentConnection?.NO_CONNECTION_FLIGHTS}
        </h1>
      )}
    </div>
  );
};

export default ConnectionFlights;
