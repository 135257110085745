import React, { useState } from 'react';
import ChatItem from '../AllChats/ChatItem';
import classNames from 'classnames';
import { NO_ROLE_CHECKEDIN, TEAM_CHAT } from '../../../utils/constants';

export interface IMessage {
  text: string;
  time?: string;
  role: string;
}

interface IChats {
  roleName: string;
  roleGroup: string;
  messagesCount?: number;
  message?: IMessage;
  isParticipantCheckedIn?: boolean;
  phoneNumbers: string[];
}

interface IFlightChatRoles {
  className: string;
  handleChatSelect: (chatScreen: string | null) => void;
  activeChat: string | null;
  chats?: IChats[];
}

const FlightChatRoles = ({
  className,
  handleChatSelect,
  activeChat,
  chats,
}: IFlightChatRoles) => {
  const [showPhoneForRole, setShowPhoneForRole] = useState('');

  return (
    <div className={classNames(className, 'overflow-y-auto')}>
      {chats?.length ? (
        chats?.map((chat: IChats) => (
          <ChatItem
            key={chat.roleGroup}
            title={chat.roleName}
            messagesCount={chat.messagesCount}
            onChatSelect={() => handleChatSelect(chat.roleGroup)}
            message={chat.message}
            isActive={activeChat === chat.roleGroup}
            isParticipantCheckedIn={chat.isParticipantCheckedIn}
            showPhone={showPhoneForRole === chat.roleGroup}
            toggle={() =>
              showPhoneForRole === chat.roleGroup
                ? setShowPhoneForRole('')
                : setShowPhoneForRole(chat.roleGroup)
            }
            phoneNumbers={chat.phoneNumbers}
            showPhoneIcon={chat.roleGroup !== TEAM_CHAT.roleGroup}
          />
        ))
      ) : (
        <div className="text-grey-60 text-24 font-body-bold h-full flex items-center justify-center dark:text-grey-25 p-24">
          {NO_ROLE_CHECKEDIN}
        </div>
      )}
    </div>
  );
};

export default FlightChatRoles;
