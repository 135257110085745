import React from 'react';
import SVG from 'react-inlinesvg';
import icons from './icons';

export type IconVariant =
  | 'star'
  | 'logout'
  | 'plane'
  | 'cancel'
  | 'close'
  | 'alert'
  | 'search'
  | 'upsidePlane'
  | 'arrow'
  | 'mute'
  | 'arrowUp'
  | 'info'
  | 'sunRising'
  | 'sun'
  | 'moon'
  | 'arrowExpand'
  | 'call'
  | 'lightning'
  | 'arrowCollapse'
  | 'gear'
  | 'home'
  | 'baggage'
  | 'person'
  | 'triangleUp'
  | 'triangleDown'
  | 'circle'
  | 'seat'
  | 'people'
  | 'arrowBack'
  | 'menu'
  | 'backArrow'
  | 'reload'
  | 'listMenu'
  | 'message'
  | 'feedback'
  | 'arrowPrevious'
  | 'arrowNext'
  | 'pen'
  | 'checkmark'
  | 'unmute'
  | 'checked'
  | 'unchecked'
  | 'filter'
  | 'admin'
  | 'deleteIcon'
  | 'chat'
  | 'takeoff'
  | 'QR'
  | 'messageSending'
  | 'messageSent'
  | 'messageDelivered'
  | 'messageRead'
  | 'messageFailed'
  | 'plus'
  | 'minus'
  | 'more'
  | 'default';

interface IIcon {
  variant: IconVariant;
  className?: string;
  height?: number | string;
  width?: number | string;
}

const Icon = ({
  variant = 'default',
  className,
  height = 16,
  width = 16,
  ...others
}: IIcon) => {
  const src = icons[variant];
  return (
    <SVG
      src={src}
      className={className}
      height={height}
      width={width}
      {...others}
    />
  );
};

export default Icon;
