import React from 'react';
import classNames from 'classnames';
import PulseNotificationIcon from '../PulseNotificationIcon/PulseNotificationIcon';
import { useToggle } from '../../utils/hooks/useToggle';
import { CheckedInResponsableRole } from '../../redux/reducers/checkinReducer';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../redux/reducers/authReducer';
import { PhoneItem } from '../StationContact/Phone';
import { useDeviceBreakpoint } from '../../utils/hooks/useDeviceBreakpoint';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import { callNumber } from '../../utils/callNumber';
import { DEVICE_TYPE } from '../../utils/constants';

interface ITeamContactItem {
  role: CheckedInResponsableRole;
  isOnTelephony?: boolean;
}

const TeamContactItem = ({ role, isOnTelephony = false }: ITeamContactItem) => {
  const areCheckedInUsers = !!role.checkedInUsers.length;
  const [showPhone, toggle] = useToggle(false);
  const [showQR, setShowQR] = useToggle(false);
  const currentUserId = useSelector(selectUserId);

  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);

  const phonesList = role.checkedInUsers.map((user) => ({
    phone: user.phone,
    userId: user.userId,
  }));

  const isOnlyOne = phonesList.length <= 1;

  const showNumberOnMobile = isMobile ? false : isOnlyOne;

  const textClass = classNames(
    'flex items-center text-primary dark:text-grey-12 text-16 w-full mobile:p-24 ml-0 pr-32 dark:bg-grey-90 dark:border-b-grey-60 mobile:border-l-0 border-white dark:border-l-grey-90',
    {
      'font-head-bold text-18 border-b-1 border-b-grey-12 py-[19px] pl-24':
        isOnTelephony,
    }
  );
  return (
    <div className="flex flex-col">
      <div
        className="relative flex justify-between flex-col"
        onClick={() =>
          callNumber(
            showNumberOnMobile,
            phonesList?.[0]?.phone,
            showQR,
            setShowQR,
            toggle,
            isMobile
          )
        }>
        <div
          className={classNames(textClass, {
            'ml-24': !areCheckedInUsers && !isOnTelephony,
            'pl-[48px] mobile:pl-[48px]': !areCheckedInUsers && isOnTelephony,
            'w-full mobile:p-24 py-[19px] ml-0 pr-32 dark:bg-grey-90 pl-24':
              isOnTelephony,
            'border-none': showPhone,
          })}
          role="status">
          <PulseNotificationIcon
            className={classNames({
              'block mr-8': areCheckedInUsers,
              hidden: !areCheckedInUsers,
            })}
          />
          {role.name}
        </div>
        <Button
          Icon={
            <Icon
              className="cursor-pointer dark:fill-grey-12 block"
              variant="call"
              height={18}
            />
          }
          className={classNames(
            'absolute m-auto desktop:top-[26px] laptop:top-[26px] tablet:top-[26px] right-24 mobile:top-[27px]',
            {
              'm-auto desktop:top-[4px] laptop:top-[4px] tablet:top-[4px] right-24 mobile:top-[27px]':
                !isOnTelephony,
            }
          )}
        />
      </div>
      <div
        className={classNames('', {
          'border-b-1 pb-8': showPhone,
          'px-24': isOnTelephony,
          'h-[0px]': !showPhone,
        })}>
        {phonesList?.map(({ phone, userId }) => (
          <PhoneItem
            key={phone}
            number={phone}
            showPhone={showPhone}
            toggle={toggle}
            isMe={userId === currentUserId}
            isOnTelephony={isOnTelephony}
            isOnlyOne={showNumberOnMobile}
            showQR={showQR}
            setShowQR={setShowQR}
          />
        ))}
      </div>
    </div>
  );
};

export default TeamContactItem;
