import React, {
  Dispatch,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import Button from '../Button/Button';
import classNames from 'classnames';
import {
  DAY_PERIOD,
  EMPTY_STATE,
  ETD_SETTINGS,
  MINUTES_IN_DAY,
  MINUTES_IN_HOUR,
  ETD_TIME_FORMATTING,
  TIME_FORMAT_12,
  UPDATE_ETD_ERROR,
  UTC_ZERO,
} from '../../utils/constants';
import TimePickerInput, {
  ITimePicker,
  timePickerReducer,
} from '../BoardingTime/TimePickerInput';
import MenuHeader from '../Navigation/Header/MenuHeader/MenuHeader';
import { setIsMenuOpen } from '../../redux/reducers/headerReducer';
import { useDispatch, useSelector } from 'react-redux';
import { BoardingReason, IataDelayCode } from '../../utils/generated/graphql';
import {
  select24Format,
  selectUTC,
} from '../../redux/reducers/settingsReducer';
import {
  getCarrierFromFlighId,
  getCurrentTimeObject,
  getFlightName,
  getTimeObject,
} from '../../utils/helpers';
import { setIsEtdSettingsOpen } from '../../redux/reducers/etdSettingsReducer';
import EtdReasons from './ETDReasons';
import { useGetDelayCodes } from '../../utils/hooks/useGetDelayCodes';
import { useUpdateEtd } from '../../utils/hooks/useUpdateEtd';
import moment from 'moment';
import { addError } from '../../redux/reducers/notificationReducer';

const textClassName =
  'flex items-center text-12 text-grey-40 dark:text-white font-body-text px-16';

const noReasonsTextClassName =
  'text-16 text-grey-40 dark:text-white font-body-text px-16 py-32 mx-auto';

interface IEtdSettings {
  handleCloseEtdSettings: () => void;
  flightId: string;
  airlineColor: string;
  UTCDiff: string;
  departureTime: {
    time: string;
    delay: string | null;
  };
  existingDelayCodes: IataDelayCode[];
  disabledETD?: boolean;
  registration?: string;
}

export interface IReason extends BoardingReason {
  subcode: string;
  code: string;
  isSelected: boolean;
}

const mapIataCodesToReasons = (
  existingCodes: IataDelayCode[],
  delayCodes: IataDelayCode[] | null
): IReason[] => {
  if (delayCodes === null) return [];

  return existingCodes.map((el) => {
    const currentCode = el.subcode !== '' ? el.subcode : el.code;

    const reason = delayCodes.find(
      (reason) =>
        (reason.subcode !== '' ? reason.subcode : reason.code) === currentCode
    );

    return {
      id: currentCode,
      reason: reason?.description ?? EMPTY_STATE,
      isSelected: true,
      subcode: reason?.subcode ?? '',
      code: reason?.code ?? '',
    };
  });
};

export const getDayAndMonthForMoment = (flightId) => {
  const splittedId = flightId?.split('-') ?? [];
  return {
    month: parseInt(splittedId?.[0]?.substring(4, 6) ?? '') - 1,
    day: parseInt(splittedId?.[0]?.substring(6, 8) ?? ''),
  };
};

const EtdSetting = ({
  handleCloseEtdSettings,
  flightId,
  airlineColor,
  UTCDiff = UTC_ZERO,
  departureTime,
  existingDelayCodes = [],
  disabledETD,
  registration,
}: IEtdSettings) => {
  const dispatch = useDispatch();

  if (UTCDiff === '') {
    UTCDiff = UTC_ZERO;
  }

  const { day: flightDay, month: flightMonth } =
    getDayAndMonthForMoment(flightId);

  const genericReasonId = '99';

  const carrier = getCarrierFromFlighId(flightId);
  const { data, loading } = useGetDelayCodes(carrier);

  const [etdUpdateReasons, setEtdUpdateReasons] = useState<IReason[]>([]);
  const [reasons, setReasons] = useState(data);

  const { onUpdateEtd, loading: isLoadingETD } = useUpdateEtd();

  useEffect(() => {
    !loading && setReasons(data);
  }, [data, loading]);

  useEffect(() => {
    setEtdUpdateReasons(mapIataCodesToReasons(existingDelayCodes, reasons));
  }, [reasons]);

  if (
    etdUpdateReasons.length > 1 &&
    etdUpdateReasons.at(-1)?.id === genericReasonId
  ) {
    const reasonsCopy = [...etdUpdateReasons];

    reasonsCopy.unshift(...reasonsCopy.splice(-1));

    setEtdUpdateReasons(reasonsCopy);
  }

  const selectedReasonsNo = etdUpdateReasons.filter(
    (reason) => reason.isSelected
  ).length;

  const [showReasonsList, setShowReasonsList] = useState(false);

  const isUTC = useSelector(selectUTC);
  const is24Format = useSelector(select24Format);

  const buttonText = etdUpdateReasons.length
    ? ETD_SETTINGS.EDIT_REASONS
    : ETD_SETTINGS.ADD_REASONS;

  const departureTimeArray = departureTime.time.split(':');
  const initialHours = parseInt(departureTimeArray[0]);
  const initialMinutes = parseInt(departureTimeArray[1]);
  const initialPeriod = is24Format
    ? DAY_PERIOD.AM
    : departureTimeArray[1]?.split(' ')?.at(1) ?? DAY_PERIOD.AM;
  const delayMinutes = parseInt(departureTime.delay ?? '0');

  const isTimeParsed = !(isNaN(initialHours) || isNaN(initialMinutes));

  const initialTime = isTimeParsed
    ? getTimeObject(
        initialHours,
        initialMinutes,
        initialPeriod,
        is24Format,
        isUTC,
        UTCDiff
      )
    : getCurrentTimeObject(isUTC, UTCDiff);

  const initialState = {
    hour: initialTime.format(is24Format ? 'HH' : 'hh'),
    minutes: initialTime.format('mm'),
    dayPeriod: initialTime.format('A'),
    isDayPeriodActive: false,
    focusedInput: null,
    isInputActive: false,
    isTomorrow: false,
  };

  const [state, dispatchTimePicker]: [ITimePicker, Dispatch<any>] = useReducer(
    timePickerReducer,
    initialState
  );
  const initialStateValues = useRef(state);

  useEffect(() => {
    const delayTimeAdded = getTimeObject(
      initialStateValues.current.hour,
      initialStateValues.current.minutes,
      initialStateValues.current.dayPeriod,
      is24Format,
      isUTC,
      UTCDiff
    );

    dispatchTimePicker({
      type: 'SET_TIME',
      payload: {
        hour: delayTimeAdded.format(is24Format ? 'HH' : 'hh'),
        minutes: delayTimeAdded.format('mm'),
      },
    });
  }, [UTCDiff, delayMinutes, is24Format, isUTC]);

  const calculatedMinutes =
    (state.hour - initialStateValues.current.hour) * MINUTES_IN_HOUR +
    (state.minutes - initialStateValues.current.minutes);

  const delay =
    state.isTomorrow && !!calculatedMinutes
      ? MINUTES_IN_DAY + calculatedMinutes
      : delayMinutes + calculatedMinutes;

  const handleReasonListClose = () => {
    setShowReasonsList(false);
  };

  const buttonClassNames = classNames(
    'rounded-4 bg-green w-auto py-12 mx-[30px] mt-[18px] mb-32',
    {
      'bg-grey-12': !selectedReasonsNo || delay <= 0 || isLoadingETD,
    }
  );

  const addButtonClassNames = classNames(
    'rounded-4  w-auto py-12 mx-[30px] mt-[18px]',
    { 'bg-grey-12': disabledETD, 'bg-primary': !disabledETD }
  );

  const onConfirmButtonClick = async () => {
    const selectedTime =
      state.hour +
      ':' +
      state.minutes +
      ' ' +
      state.dayPeriod +
      (isUTC ? UTC_ZERO : UTCDiff);

    const departureTimeFormat =
      departureTime?.time + (isUTC ? UTC_ZERO : UTCDiff);

    const etdTimeEstimated = isUTC
      ? moment(selectedTime, TIME_FORMAT_12)
          .month(flightMonth)
          .date(flightDay)
          .add(state.isTomorrow ? 1 : 0, 'days')
          .parseZone()
      : moment(selectedTime, TIME_FORMAT_12)
          .month(flightMonth)
          .date(flightDay)
          .add(state.isTomorrow ? 1 : 0, 'days')
          .utc();

    const etdDepartureTime = isUTC
      ? moment(departureTimeFormat.toLocaleUpperCase(), TIME_FORMAT_12)
          .month(flightMonth)
          .date(flightDay)
          .parseZone()
      : moment(departureTimeFormat.toLocaleUpperCase(), TIME_FORMAT_12)
          .month(flightMonth)
          .date(flightDay)
          .utc();

    const delayCodesArray = etdUpdateReasons?.map((codes) => {
      return {
        code: codes?.code ?? '',
        subcode: codes?.subcode ?? '',
      };
    });

    const result = await onUpdateEtd({
      flightId: flightId,
      delayCodes: delayCodesArray,
      registration: registration ?? '',
      standardDepartureTime:
        moment(etdDepartureTime).format(ETD_TIME_FORMATTING),
      estimatedDepartureTime:
        moment(etdTimeEstimated).format(ETD_TIME_FORMATTING),
      message: null,
    });

    if (result?.status && !result?.errors.length) {
      handleCloseEtdSettings();
    } else if (!result?.status && result?.errors.length) {
      result.errors.forEach((error) => dispatch(addError(error?.message)));
    } else if (!result?.status && !result?.errors.length) {
      dispatch(addError(UPDATE_ETD_ERROR));
    }
  };

  const renderReasonsList = () => {
    return (
      <div className="pb-[40px] overflow-y-scroll">
        {etdUpdateReasons.map((etdUpdateReason) => {
          return (
            <li
              key={etdUpdateReason.id}
              className={classNames(
                'list-none text-18 px-16 text-primary font-body-text border-b-1 border-b-grey-12 py-4 last:border-b-0 flex justify-between'
              )}>
              <input
                id={etdUpdateReason.id ?? ''}
                type="checkbox"
                className="hidden"
              />
              <div className="flex flex-row w-full">
                <label
                  htmlFor={etdUpdateReason.id ?? ''}
                  className="w-56 top-0 bottom-0 my-auto">
                  {etdUpdateReason.id}
                </label>
                <label htmlFor={etdUpdateReason.id ?? ''} className="w-full">
                  {etdUpdateReason.reason}
                </label>
              </div>
            </li>
          );
        })}
      </div>
    );
  };

  return (
    <div className="h-full overflow-y-clip flex flex-col relative dark:bg-grey-90">
      <MenuHeader
        className="tablet:hidden laptop:hidden desktop:hidden"
        themeColor={classNames(airlineColor, 'dark:bg-grey-90')}
        onMenuClick={() => {
          dispatch(setIsMenuOpen(true));
        }}
        hasBackArrow
        onBackArrowClick={() => {
          dispatch(setIsEtdSettingsOpen(false));
        }}
      />

      {showReasonsList ? (
        <EtdReasons
          handleCloseEtdSettings={handleReasonListClose}
          etdUpdateReasons={etdUpdateReasons}
          setSelectedUpdateReasons={setEtdUpdateReasons}
          delayReasons={data}
        />
      ) : (
        <>
          <div className="p-16 flex justify-between content-center items-center border-b-grey-12 border-b-1 pb-16">
            <div>
              <div className="text-14 text-primary dark:text-white font-body-text uppercase font-semibold">
                {ETD_SETTINGS.TITLE}
              </div>
              <div className="text-12 text-primary dark:text-white font-bold font-body-text">
                {getFlightName(flightId)}
              </div>
            </div>
            <Button
              text={ETD_SETTINGS.CANCEL}
              className="w-[110px] bg-grey-12 dark:bg-white rounded-4 h-44 flex items-center justify-center"
              textClassName="text-14 !text-primary font-body-text leading-[18px]"
              onClick={handleCloseEtdSettings}
            />
          </div>
          <TimePickerInput
            isOnETD
            currentState={state}
            dispatch={dispatchTimePicker}
            calculatedDelay={delay}
            UTCDiff={UTCDiff}
            initialTime={{
              hour: initialStateValues.current.hour,
              minutes: initialStateValues.current.minutes,
              dayPeriod: initialStateValues.current.dayPeriod,
            }}
            disabledButtons={disabledETD}
          />

          <div className={textClassName}>{ETD_SETTINGS.DELAY_REASONS}</div>
          {selectedReasonsNo !== 0 ? (
            renderReasonsList()
          ) : (
            <div className={noReasonsTextClassName}>
              {ETD_SETTINGS.NO_REASONS_SELECTED}
            </div>
          )}

          <div className="w-full grid mt-auto mb-0">
            <Button
              text={buttonText}
              className={addButtonClassNames}
              textClassName={classNames(
                'font-body-text font-bold text-white text-14',
                {
                  'dark:text-grey-40': disabledETD,
                }
              )}
              onClick={() => setShowReasonsList(true)}
              disabled={disabledETD}
            />

            <Button
              text={ETD_SETTINGS.CONFIRM}
              className={buttonClassNames}
              textClassName={classNames(
                'font-body-text font-bold text-white text-14 dark:text-grey-40',
                {
                  'dark:text-white':
                    (!!selectedReasonsNo && delay > 0) ||
                    disabledETD ||
                    isLoadingETD,
                }
              )}
              disabled={delay <= 0 || !selectedReasonsNo || isLoadingETD}
              onClick={onConfirmButtonClick}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EtdSetting;
