import React, { ReactNode, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button/Button';
import Tailwing from '../Tailwing/Tailwing';
import Title from '../Title/Title';
import Icon from '../Icon/Icon';
import FavouriteButton from '../CustomButtons/FavouriteButton/FavouriteButton';
import {
  FLIGHT_DETAILS_HEADER,
  AIRLINE_COLOR_MAP,
  INITIAL_STATE,
  EMPTY_STATE,
  N_A,
  LIGHT_FONT_AIRLINES,
  ACTIONS_DROPDOWN,
  ACTION_ITEMS,
} from '../../utils/constants';
import { setIsMenuOpen } from '../../redux/reducers/headerReducer';
import {
  select24Format,
  selectUTC,
} from '../../redux/reducers/settingsReducer';
import { calculateTime } from '../../utils/calculateTime';
import { IBestTimeLogic, ITimesObject } from '../../utils/types';
import { selectUserId } from '../../redux/reducers/authReducer';
import { refreshCountdown } from '../../redux/reducers/reloadReducer';
import { clearNotifications } from '../../redux/reducers/notificationReducer';
import { useToggle } from '../../utils/hooks/useToggle';
import { useGetMyFlightsCached } from '../../utils/hooks/useGetMyFlightsCached';
import { ApolloError } from '@apollo/client';
import CheckinContainer from './CheckinDropdown/CheckinContainer';
import { RootState } from '../../redux/store';
import { useClickOutside } from '../../utils/hooks/useClickOutside';
import SingleSelectDropdown from '../SingleSelectDropdown/SingleSelectDropdown';
import { selectCheckedInUsers } from '../../redux/reducers/checkinReducer';
import { Permission } from '../../utils/generated/graphql';
import useCheckPermissions, {
  CheckType,
} from '../../utils/hooks/useCheckPermissions';
import { checkCUTEDevice, getAirlineKey } from '../../utils/helpers';
import {
  OverlayModule,
  setOpenOverlay,
} from '../../redux/reducers/overlayReducer';
import { useAddMyFlight } from '../../utils/hooks/useAddMyFlight';
import { useRemoveMyFlight } from '../../utils/hooks/useRemoveMyFlights';

interface IFlightDetailsHeader {
  flightId: string;
  flightName: string;
  time: string;
  departureAirport: string;
  arrivalAirport: string;
  gate: string;
  position: {
    value: string;
    extraDetails?: string;
  } | null;
  type: string;
  registration: string;
  actualArrivalTime: string;
  isFavourite: boolean;
  previousLocation: string;
  aircraftRotation?: {
    id: string;
    arrivalTimes?: ITimesObject;
    inbound: string;
    departure: string;
    arrival: string;
    gate: string;
    pos: string;
    bestTimeLogic: IBestTimeLogic;
  };
  loading: boolean;
  checkedInRole: string | null;
  error?: ApolloError | Error;
  station?: string;
}

const topContainerClassNames = 'relative flex';
const bottomContainerClassNames = '';
const iconClassNames = 'fill-white';
const backButtonClassNames = 'flex gap-2.5 items-center';
const backButtonTextClassNames =
  'font-head-bold text-14 text-white leading-[19px]';
const detailsContainerClassNames =
  'flex flex-row mobile:flex-col justify-between h-[193px]';
const detailsClassNames = 'relative flex flex-col z-30';
const flightNameClassNames =
  'block text-white mt-[70px] mobile:mb-40 mobile:mt-[56px] mb-32 font-head-bold text-56 mobile:text-[42px] leading-[56px] mobile:leading-[42px]';
const detailsClass = 'flex mt-4 text-18 font-body-thin h-[19px]';
const routeClass =
  'text-white text-18 flex mobile:flex-col gap-x-40 leading-[19px]';
const routeDetailsClassNames =
  'flex gap-8 items-center mt-4 text-18 font-body-thin h-[19px]';
const gatePosClass =
  'text-white text-18 flex gap-x-40 mobile:gap-x-[67px] mobile:mt-16 leading-[19px]';
const extraDetailsClassNames =
  'self-end text-12 text-white font-body-text leading-[12px]';
const typeRegClass =
  'text-white text-18 flex gap-x-40 mobile:gap-x-72 mobile:mt-16 leading-[19px]';
const tailwingMobileClassNames =
  'absolute right-24 bottom-0 tablet:hidden laptop:hidden desktop:hidden z-20';
const tailwingLargeClassNames = 'absolute right-[20%] bottom-0 mobile:hidden';
const favouriteButtonClassNames =
  'relative mobile:mt-40 tablet:self-end laptop:self-end desktop:self-end flex items-center gap-16 z-50';
const expandedContainerClassNames =
  'flex mobile:flex-col gap-40 mobile:gap-16 h-[75px] mobile:h-[134px] py-[20px] px-24 bg-white dark:bg-grey-90';
const expandedDividerClassNames = 'flex gap-40 mobile:gap-32';
const expandedItemBoldClassNames =
  'font-head-bold text-18 leading-[19px] text-primary dark:text-grey-12 mt-4';
const expandedItemClassNames =
  'font-body-thin text-18 leading-[19px] text-primary dark:text-grey-12 mt-4';
const topContainerMobile =
  'w-full flex justify-between tablet:hidden laptop:hidden desktop:hidden';
const topContainerTablet = 'w-full flex justify-between mobile:hidden';
const routeButtonsClassNames = 'flex gap-38';

const getState = (departureAirport: string, arrivalAirport: string) =>
  (departureAirport === EMPTY_STATE && arrivalAirport === EMPTY_STATE) ||
  (departureAirport === '' && arrivalAirport === '')
    ? EMPTY_STATE
    : INITIAL_STATE;

const isEmptyRoute = (departureAirport?: string, arrivalAirport?: string) =>
  (departureAirport?.trim() === '' && arrivalAirport?.trim() === '') ||
  (departureAirport === EMPTY_STATE && arrivalAirport === EMPTY_STATE);

const FlightDetailsHeader = ({
  flightId,
  flightName = '',
  time,
  departureAirport,
  arrivalAirport,
  gate = EMPTY_STATE,
  position = null,
  type = EMPTY_STATE,
  registration = EMPTY_STATE,
  actualArrivalTime,
  isFavourite,
  previousLocation = FLIGHT_DETAILS_HEADER.MY_FLIGHTS,
  aircraftRotation,
  loading,
  checkedInRole,
  error,
  station,
  ...others
}: IFlightDetailsHeader) => {
  const userId = useSelector(selectUserId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const airlineKey = getAirlineKey(flightName);
  const airlineColor = AIRLINE_COLOR_MAP[airlineKey];
  const containerClassNames =
    'relative h-[260px] mobile:h-[423px] p-24 dark:bg-grey-90 dark:border-b-2 dark:border-b-black';
  const { data, refetchMyFlightsCache } = useGetMyFlightsCached(userId);
  const isCacheFlightFavorite = data?.find(
    (flight) => flight.flightId === flightId
  )?.isFavourite;

  const favouritesFlights = data ?? [];
  const isCheckedIn = useSelector(
    (state: RootState) => state.headerReducer.isCheckedIn
  );

  const isCUTEDevice = checkCUTEDevice();

  const checkedInRoles = useSelector(selectCheckedInUsers);

  const currentRoleId = checkedInRoles[flightId ?? '']?.myRole?._id;
  const hasMyFlightsAccess = useCheckPermissions([
    Permission.MANAGE_MY_FLIGHTS,
  ]);

  const hasBoardingUpdatePermission = useCheckPermissions(
    [Permission.UPDATE_BOARDING_TIME],
    CheckType.FULL,
    [currentRoleId]
  );

  const hasBriefingPackagePermission = useCheckPermissions(
    [Permission.BRIEFING_PACKAGE],
    CheckType.FULL,
    [currentRoleId]
  );

  const excludedStations =
    process.env.REACT_APP_TAC_ETD_STATIONS_EXCLUDED?.replace(' ', '').split(
      ','
    ) ?? [];

  const hasEtdUpdatePermission =
    useCheckPermissions(
      [Permission.UPDATE_ETD],
      CheckType.FULL,
      [currentRoleId],
      station ?? ''
    ) && !excludedStations.includes(station ?? '');

  const hasCheckInPermission = useCheckPermissions(
    [Permission.CHECK_IN],
    CheckType.PARTIAL
  );

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const clickOutsideRef = useRef<HTMLButtonElement>(null);
  useClickOutside(clickOutsideRef, () => {
    setIsDropdownOpen((prevIsOpen) => (prevIsOpen ? false : prevIsOpen));
  });

  const previousPage = previousLocation.includes('My Flights')
    ? '/favourites'
    : '/search';

  const getPrevNextRoute = (type: string) => {
    const currentFlightIndex =
      favouritesFlights?.findIndex((flight) => flight.flightId === flightId) ??
      0;
    if (type === 'prev') {
      return currentFlightIndex > 0
        ? currentFlightIndex - 1
        : favouritesFlights.length - 1;
    }
    return currentFlightIndex < favouritesFlights.length - 1
      ? currentFlightIndex + 1
      : 0;
  };

  const handlePrevNextRoute = (type: string) => {
    const route = getPrevNextRoute(type);
    const newRoute = `/favourites/${favouritesFlights[route]?.flightId}/process`;
    navigate(newRoute);
    dispatch(refreshCountdown());
    dispatch(clearNotifications());
  };

  const titleColorClass = classNames({
    'text-white': LIGHT_FONT_AIRLINES.includes(airlineKey),
    'text-grey-40': !LIGHT_FONT_AIRLINES.includes(airlineKey),
  });

  const isUTC = useSelector(selectUTC);
  const is24Format = useSelector(select24Format);
  const aircraftRotationTime = calculateTime(
    isUTC,
    is24Format,
    aircraftRotation?.arrivalTimes
  );

  const { onAddToFavourites } = useAddMyFlight('process');
  const { onRemoveFromFavourites } = useRemoveMyFlight();

  const onAddFlight = async () => {
    await onAddToFavourites([flightId]);
    return true;
  };

  const onRemoveFlight = async () => {
    await onRemoveFromFavourites([flightId]);
    return true;
  };
  const onFavoriteClick = async () => {
    return isColored ? onRemoveFlight() : onAddFlight();
  };

  useEffect(() => {
    setIsColored(isFavourite);
  }, [isFavourite]);

  const [isColored, setIsColored] = useState(isFavourite);

  const onFavoriteClickHandler = async () => {
    try {
      setIsColored((prev) => !prev);
      const favouriteResponse = await onFavoriteClick?.();
      if (favouriteResponse) {
        await refetchMyFlightsCache();
      }
    } catch (error) {
      setIsColored((prev) => !prev);
    }
  };

  const isSingleFavourite = favouritesFlights.length === 1;

  const actionsListItems = [
    ...(hasBoardingUpdatePermission ? [{ name: ACTION_ITEMS.BOARDING }] : []),
    ...(hasEtdUpdatePermission ? [{ name: ACTION_ITEMS.ETD_SETTINGS }] : []),
    ...(hasBriefingPackagePermission && !isCUTEDevice
      ? [{ name: ACTION_ITEMS.BRIEFING_PACKAGE }]
      : []),
  ];

  const onItemSelection = (item: { name: ACTION_ITEMS }, _: ReactNode, e) => {
    e?.stopPropagation();
    setIsDropdownOpen((prev) => !prev);
    switch (item.name) {
      case ACTION_ITEMS.BOARDING: {
        dispatch(setOpenOverlay(OverlayModule.BOARDING_TIME_UPDATE));
        break;
      }
      case ACTION_ITEMS.ETD_SETTINGS: {
        dispatch(setOpenOverlay(OverlayModule.ETD_SETTINGS));
        break;
      }
      case ACTION_ITEMS.BRIEFING_PACKAGE: {
        dispatch(setOpenOverlay(OverlayModule.BRIEFING_PACKAGE));
        break;
      }
    }
  };

  return (
    <div {...others}>
      <div className={classNames(containerClassNames, airlineColor)}>
        <div className={topContainerClassNames}>
          <div className={topContainerTablet}>
            <Button
              Icon={
                <Icon
                  width={12}
                  height={12}
                  variant={
                    previousLocation.includes('My Flights')
                      ? 'upsidePlane'
                      : 'search'
                  }
                  className={iconClassNames}
                />
              }
              text={`To ${previousLocation}`}
              textClassName={backButtonTextClassNames}
              className={backButtonClassNames}
              onClick={() =>
                navigate(previousPage, { state: { shouldKeepQuery: true } })
              }
              data-testid="back-button"
            />
            {(isFavourite || isCheckedIn) && !!favouritesFlights?.length && (
              <div className={routeButtonsClassNames}>
                <Button
                  Icon={
                    <Icon
                      width={16}
                      height={16}
                      variant="arrowPrevious"
                      className={classNames(iconClassNames, {
                        'opacity-50': isSingleFavourite,
                      })}
                    />
                  }
                  disabled={isSingleFavourite}
                  text={'Prev Flight'}
                  textClassName={classNames(backButtonTextClassNames, {
                    'opacity-50': isSingleFavourite,
                  })}
                  className={backButtonClassNames}
                  onClick={() => handlePrevNextRoute('prev')}
                />
                <Button
                  Icon={
                    <Icon
                      width={16}
                      height={16}
                      variant="arrowNext"
                      className={classNames(iconClassNames, {
                        'opacity-50': isSingleFavourite,
                      })}
                    />
                  }
                  disabled={isSingleFavourite}
                  text={'Next Flight'}
                  textClassName={classNames(backButtonTextClassNames, {
                    'opacity-50': isSingleFavourite,
                  })}
                  className={classNames(
                    backButtonClassNames,
                    'flex-row-reverse	'
                  )}
                  onClick={() => handlePrevNextRoute('next')}
                />
              </div>
            )}
          </div>
          <div className={topContainerMobile}>
            <Button
              Icon={
                <Icon
                  width={18}
                  height={18}
                  variant={
                    previousLocation.includes('My Flights')
                      ? 'upsidePlane'
                      : 'search'
                  }
                  className={iconClassNames}
                />
              }
              onClick={() =>
                navigate(
                  previousLocation.includes('My Flights')
                    ? '/favourites'
                    : '/search'
                )
              }
              data-testid="back-button"
            />
            {(isFavourite || isCheckedIn) && !!favouritesFlights?.length && (
              <div className={routeButtonsClassNames}>
                <Button
                  Icon={
                    <Icon
                      width={16}
                      height={16}
                      variant="arrowPrevious"
                      className={classNames(iconClassNames, {
                        'opacity-50': isSingleFavourite,
                      })}
                    />
                  }
                  text={'Prev'}
                  className={backButtonClassNames}
                  textClassName={classNames(backButtonTextClassNames, {
                    'opacity-50': isSingleFavourite,
                  })}
                  disabled={isSingleFavourite}
                  onClick={() => handlePrevNextRoute('prev')}
                />
                <Button
                  Icon={
                    <Icon
                      width={16}
                      height={16}
                      variant="arrowNext"
                      className={classNames(iconClassNames, {
                        'opacity-50': isSingleFavourite,
                      })}
                    />
                  }
                  text={'Next'}
                  disabled={isSingleFavourite}
                  textClassName={classNames(backButtonTextClassNames, {
                    'opacity-50': isSingleFavourite,
                  })}
                  className={classNames(
                    backButtonClassNames,
                    'flex-row-reverse	'
                  )}
                  onClick={() => handlePrevNextRoute('next')}
                />
              </div>
            )}
            <Button
              Icon={<Icon variant="menu" className={iconClassNames} />}
              onClick={() => dispatch(setIsMenuOpen(true))}
              data-testid="menu-button"
            />
          </div>
        </div>
        <div className={bottomContainerClassNames}>
          <div className={detailsContainerClassNames}>
            <div className={detailsClassNames}>
              <h1 className={flightNameClassNames}>{flightName}</h1>
              <div className={routeClass}>
                <Title
                  titleColorClass={titleColorClass}
                  title={FLIGHT_DETAILS_HEADER.ROUTE}>
                  <div className={routeDetailsClassNames}>
                    {!isEmptyRoute(departureAirport, arrivalAirport) ? (
                      <>
                        {departureAirport}
                        <Icon
                          variant="arrow"
                          width={14}
                          height={14}
                          className="fill-white"
                        />
                        {arrivalAirport}
                      </>
                    ) : (
                      getState(departureAirport, arrivalAirport)
                    )}
                  </div>
                </Title>
                <div className={gatePosClass}>
                  <Title
                    titleColorClass={titleColorClass}
                    title={FLIGHT_DETAILS_HEADER.GATE}>
                    <div className={detailsClass}>{gate || EMPTY_STATE}</div>
                  </Title>
                  <Title
                    titleColorClass={titleColorClass}
                    title={FLIGHT_DETAILS_HEADER.POS}>
                    <div className="flex gap-8">
                      <div className={detailsClass}>
                        {position?.value || EMPTY_STATE}
                      </div>
                      {position?.extraDetails && (
                        <div className={extraDetailsClassNames}>
                          {position.extraDetails}
                        </div>
                      )}
                    </div>
                  </Title>
                </div>
                <div className={typeRegClass}>
                  <Title
                    titleColorClass={titleColorClass}
                    title={FLIGHT_DETAILS_HEADER.TYPE}>
                    <div className={detailsClass}>{type || EMPTY_STATE}</div>
                  </Title>
                  <Title
                    titleColorClass={titleColorClass}
                    title={FLIGHT_DETAILS_HEADER.REGISTRATION}>
                    <div className={detailsClass}>
                      {registration || EMPTY_STATE}
                    </div>
                  </Title>
                </div>
              </div>
            </div>
            <Tailwing
              airlineKey={airlineKey}
              size="l"
              className={tailwingLargeClassNames}
            />
            <Tailwing
              airlineKey={airlineKey}
              size="m"
              className={tailwingMobileClassNames}
            />
            <div className={favouriteButtonClassNames}>
              {hasCheckInPermission && (
                <CheckinContainer
                  flightName={flightName}
                  flightId={flightId}
                  checkedInRole={checkedInRole}
                  disabled={!!(loading || error)}
                  className="bg-white"
                  setIsColored={setIsColored}
                />
              )}
              {hasMyFlightsAccess && (
                <FavouriteButton
                  onClick={onFavoriteClickHandler}
                  favourite={isColored}
                  disabled={!!(loading || error)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          'flex justify-between bg-white items-center pr-24 dark:bg-grey-90',
          {
            'cursor-pointer': aircraftRotation?.id,
          }
        )}
        onClick={() => {
          if (aircraftRotation?.id)
            navigate(`/search/${aircraftRotation.id}/process`);
        }}>
        <div className={expandedContainerClassNames}>
          <Title title={FLIGHT_DETAILS_HEADER.INBOUND}>
            <div className={expandedItemBoldClassNames}>
              {aircraftRotation?.inbound ?? N_A}
            </div>
          </Title>
          <div className={expandedDividerClassNames}>
            <Title title={FLIGHT_DETAILS_HEADER.ROUTE}>
              <div
                className={classNames(
                  expandedItemClassNames,
                  routeDetailsClassNames
                )}>
                {!isEmptyRoute(
                  aircraftRotation?.departure || '',
                  aircraftRotation?.arrival || ''
                ) ? (
                  <>
                    {aircraftRotation?.departure}
                    <Icon
                      variant="arrow"
                      width={14}
                      height={14}
                      className="fill-primary dark:fill-grey-12"
                    />
                    {aircraftRotation?.arrival}
                  </>
                ) : (
                  getState(
                    aircraftRotation?.departure || '',
                    aircraftRotation?.arrival || ''
                  )
                )}
              </div>
            </Title>
            <Title title={FLIGHT_DETAILS_HEADER.GATE}>
              <div className={expandedItemClassNames}>
                {aircraftRotation?.gate ?? EMPTY_STATE}
              </div>
            </Title>
            <Title title={FLIGHT_DETAILS_HEADER.POS}>
              <div className={expandedItemClassNames}>
                {aircraftRotation?.pos ?? EMPTY_STATE}
              </div>
            </Title>
            <Title
              title={
                aircraftRotation?.bestTimeLogic?.arrivalString ?? INITIAL_STATE
              }>
              <div className={expandedItemClassNames}>
                {aircraftRotationTime === EMPTY_STATE
                  ? INITIAL_STATE
                  : aircraftRotationTime}
              </div>
            </Title>
          </div>
        </div>
        <div
          className={classNames('relative mobile:hidden', {
            hidden: !(
              hasBoardingUpdatePermission ||
              hasEtdUpdatePermission ||
              (hasBriefingPackagePermission && !isCUTEDevice)
            ),
          })}>
          <SingleSelectDropdown
            displayText={ACTIONS_DROPDOWN.TITLE}
            handleDropdownClick={(e) => {
              e.stopPropagation();
              setIsDropdownOpen((prev) => !prev);
            }}
            isOpen={isDropdownOpen}
            clickOutsideRef={clickOutsideRef}
            listItems={actionsListItems}
            handleItemSelection={onItemSelection}
            variant="large"
            className={`${airlineColor} dark:bg-white`}
            isActionButton={true}
          />
        </div>
      </div>
    </div>
  );
};

export default FlightDetailsHeader;
