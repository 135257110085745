import React, { FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  MAX_LENGTH_DESCRIPTION,
  MAX_LENGTH_SUBJECT,
  NOTIFICATION_MODAL_BODY,
} from '../../../utils/constants';
import {
  Notification,
  NotificationType,
  RoleGroup,
} from '../../../utils/generated/graphql';
import Dropdown from '../../Settings/Dropdown/Dropdown';
import Datetime from 'react-datetime';
import Title from '../../Title/Title';
import {
  select24Format,
  selectStationsList,
} from '../../../redux/reducers/settingsReducer';
import 'react-datetime/css/react-datetime.css';
import './NotificationForm.scss';
import { NOTIFICATION_FORM_ID } from '../AdminNotificationCenter';
import Input from '../../Input/Input';
import TextArea from '../../TextArea/TextArea';
import { useGetNotificationMeta } from '../../../utils/hooks/useGetNotificationMeta';
import StationInput from '../../Settings/StationInput/StationInput';

const titleClass = 'flex items-center mobile:items-start mobile:flex-col';
const inputContainerClass =
  'relative bg-white w-full border-1 border-primary rounded-4 py-2 pl-14 pr-[7px] font-body-bold text-primary text-14 appearance-none focus:outline-none mt-8 dark:bg-grey-90 dark:border-grey-12 dark:text-grey-12';
const inputClass =
  'w-full appearance-none focus:outline-none pr-8 mb-8 dark:bg-grey-90';
const titleColorClass = 'w-[70px] text-grey-40';

const IS_CATEGORY_VISIBLE = false;

const FIELD_NAMES = {
  TYPE: 'Type',
  CATEGORY: 'Category',
  STATION: 'Station',
  SUBJECT: 'Subject',
  MESSAGE: 'Message',
  START: 'Start Date',
  END: 'End Date',
  TOPIC: 'Topic',
  TARGET_ROLES: 'Target Roles',
};

const DATE_FORMAT = 'DD MMM YYYY';

export interface ISubmitFields {
  e: FormEvent<HTMLFormElement>;
  startDate: string;
  endDate: string;
  stations?: string[] | null;
  targetRoles?: RoleGroup[] | null;
  senderRole?: RoleGroup | null;
}
interface INotificationForm {
  notification?: Notification;
  handleSubmit?: (elem: ISubmitFields) => void;
}

interface ITopic {
  topicMessage: string;
  topicRoleGroup: RoleGroup;
}

export const NotificationForm = ({
  notification = undefined,
  handleSubmit,
}: INotificationForm) => {
  const { data: metaData, loading } = useGetNotificationMeta(!!notification);
  const [type, setType] = useState<NotificationType | string | null>(null);
  const [topic, setTopic] = useState<ITopic | null>(null);
  const [targetRoles, setTargetRole] = useState<string | null>(null);
  const [stations, setStations] = useState<string[]>(
    notification ? [notification.details.station ?? ''] : []
  );
  const [startDateValue, setStartDateValue] = useState(
    notification?.details?.schedule?.sendDate ?? ''
  );
  const [endDateValue, setEndDateValue] = useState(
    notification?.details?.schedule?.deleteDate ?? ''
  );
  const is24Format = useSelector(select24Format);

  const TIME_FORMAT = is24Format ? 'HH:mm' : 'hh:mm A';
  const DATE_TIME_FORMAT = 'DD MMM '.concat(TIME_FORMAT);

  useEffect(() => {
    notification
      ? setType(notification?.type)
      : setType(metaData?.types?.[0].type ?? '');
  }, [metaData, notification]);

  const typeOptions =
    metaData?.types?.map((notificationType) => ({
      value: notificationType?.type ?? '',
      selected: false,
    })) ?? [];

  const defaultTypeOptions = notification
    ? typeOptions?.map((option) => ({
        ...option,
        selected: option.value === notification.type,
      }))
    : typeOptions;

  const categoryOptions = metaData?.types
    ?.find((notificationType) => notificationType?.type === type)
    ?.categories?.map((category) => ({ value: category, selected: false }));

  const defaultCategoryOptions = notification
    ? categoryOptions?.map((option) => {
        return { ...option, selected: option.value === notification.category };
      })
    : categoryOptions;

  const defaultTopicOptions =
    metaData?.broadcastRoles?.map((broadcastEntry) => ({
      value: `${broadcastEntry?.roleName} message`,
      topicRoleGroup: broadcastEntry?.roleGroup,
      selected: false,
    })) ?? [];

  const targetRolesOptions =
    metaData?.broadcastRoles
      ?.find((entry) => topic?.topicRoleGroup === entry?.roleGroup)
      ?.targetRoles.map((role) => ({
        value: role.roleName,
        selected: false,
        roleGroup: role.roleGroup,
      })) ?? [];

  const defaultTargetRolesOptions = notification
    ? targetRolesOptions?.map((option) => ({
        ...option,
        selected:
          notification?.details.targetRoles?.findIndex(
            (targetRole) => targetRole === option.value
          ) !== -1,
      }))
    : targetRolesOptions;

  const formattedStartDate = startDateValue
    ? moment(startDateValue).format(DATE_TIME_FORMAT)
    : '';
  const formattedEndDate = endDateValue
    ? moment(endDateValue).format(DATE_TIME_FORMAT)
    : '';
  const stationsList = useSelector(selectStationsList);
  const stationOptions = stationsList?.map((station) => ({
    value: station,
    selected: false,
  }));

  const defaultStationOptions =
    type === NotificationType.STATION || type === NotificationType.ROLE
      ? notification
        ? stationOptions.map((station) =>
            station.value === notification.details.station
              ? { ...station, selected: true }
              : station
          )
        : stationOptions
      : [];

  const targetRoleGroups = targetRolesOptions.find(
    (role) => role.value === targetRoles
  )?.roleGroup;

  return (
    <form
      id={NOTIFICATION_FORM_ID}
      onSubmit={(e) =>
        handleSubmit &&
        handleSubmit({
          e,
          startDate: startDateValue,
          endDate: endDateValue,
          stations,
          targetRoles: targetRoleGroups ? [targetRoleGroups] : null,
          senderRole: topic?.topicRoleGroup,
        })
      }>
      <div className="max-h-screen space-y-[10px]">
        <div className="bg-white border border-1 mobile:border-none dark:border-grey-60 rounded-8 px-24 py-8 dark:bg-grey-90">
          <h1 className="text-primary">
            {NOTIFICATION_MODAL_BODY.NOTIFICATION_CONFIGURATION}
          </h1>
          <div className="pt-8">
            <Dropdown
              disabled={!!notification || loading}
              disabledOption={notification?.type ?? ''}
              inputClassName="w-full border-1 border-black rounded-4 py-8 pl-8 font-body-bold text-primary text-14 appearance-none focus:outline-none dark:border-grey-12 dark:text-grey-12 dark:bg-grey-90"
              className="p-0 w-full min-w-full flex flex-row items-center "
              containerClassName="w-full !mt-0 "
              titleClassName="basis-1/6 text-grey-40"
              options={defaultTypeOptions}
              handleChange={(e) => {
                setType(e);
                setTopic(null);
              }}
              title={FIELD_NAMES.TYPE + '*'}
            />
            <Dropdown
              disabled={
                !!notification || loading || type !== NotificationType.ROLE
              }
              disabledOption={''}
              inputClassName="w-full border-1 border-black rounded-4 py-8 pl-8 font-body-bold text-primary text-14 appearance-none focus:outline-none dark:border-grey-12 dark:text-grey-12 dark:bg-grey-90"
              className="p-0 w-full min-w-full flex flex-row items-center mt-[10px]"
              titleClassName="basis-1/6 text-grey-40"
              containerClassName="w-full !mt-0 "
              options={defaultTopicOptions}
              handleChange={(e) =>
                setTopic({
                  topicMessage: e,
                  topicRoleGroup:
                    defaultTopicOptions.find((topic) => topic.value === e)
                      ?.topicRoleGroup ?? RoleGroup.UNKNOWN,
                })
              }
              title={`${FIELD_NAMES.TOPIC}${
                type === NotificationType.ROLE ? `*` : ``
              }`}
              showDefaultEmpty
            />
            {IS_CATEGORY_VISIBLE ? (
              <Dropdown
                disabled={defaultCategoryOptions?.length === 0}
                disabledOption={notification?.category ?? ''}
                inputClassName="w-full border-1 border-black rounded-4 py-8 pl-8 font-body-bold text-primary text-14 appearance-none focus:outline-none dark:border-grey-12 dark:text-grey-12 dark:bg-grey-90"
                className="p-0 w-[400px] flex"
                options={defaultCategoryOptions ?? []}
                title={FIELD_NAMES.CATEGORY}
              />
            ) : topic && topic.topicRoleGroup !== RoleGroup.MANAGERONDUTY ? (
              <StationInput
                stationsArray={stations}
                onStationsChange={setStations}
                title={FIELD_NAMES.STATION}
                hasMaxNoStation={false}
                className="flex flex-row items-center mt-[10px]  !min-h-[21px]"
                titleClassName="basis-1/6  text-grey-40"
                isOnNotification={true}
                disabled={type === NotificationType.SYSTEM}
              />
            ) : (
              <Dropdown
                handleChange={setStations}
                disabled={
                  defaultStationOptions.length === 0 ||
                  type === NotificationType.SYSTEM ||
                  (type === NotificationType.ROLE && !topic)
                }
                disabledOption=""
                inputClassName="w-full border-1 border-black rounded-4 py-8 pl-8 font-body-bold text-primary text-14 appearance-none focus:outline-none dark:border-grey-12 dark:text-grey-12 dark:bg-grey-90"
                className="p-0 w-full min-w-full flex flex-row items-center mt-[10px]"
                titleClassName="basis-1/6 text-grey-40"
                containerClassName="w-full !mt-0 "
                options={defaultStationOptions}
                title={FIELD_NAMES.STATION + '*'}
                showDefaultEmpty
              />
            )}

            <Dropdown
              handleChange={setTargetRole}
              disabled={
                defaultTargetRolesOptions.length === 0 ||
                type !== NotificationType.ROLE ||
                !topic
              }
              disabledOption=""
              inputClassName="w-full border-1 border-black rounded-4 py-8 pl-8 font-body-bold text-primary text-14 appearance-none focus:outline-none dark:border-grey-12 dark:text-grey-12 dark:bg-grey-90"
              className="p-0 w-full min-w-full flex flex-row items-center mt-[10px]"
              titleClassName="basis-1/6 text-grey-40"
              containerClassName="w-full !mt-0 "
              options={defaultTargetRolesOptions}
              title={`${FIELD_NAMES.TARGET_ROLES}${
                type === NotificationType.ROLE ? `*` : ``
              }`}
              showDefaultEmpty
            />
          </div>
        </div>
        {IS_CATEGORY_VISIBLE && (
          <div className="bg-white border mobile:border-none border-1 dark:border-grey-60 rounded-8 px-24 py-8 dark:bg-grey-90">
            <h1 className="text-primary">
              {NOTIFICATION_MODAL_BODY.TARGET_AUDIENCE}
            </h1>
            <Dropdown
              inputClassName="w-full border-1 border-black rounded-4 py-8 pl-8 font-body-bold text-primary text-14 appearance-none focus:outline-none dark:border-grey-12 dark:text-grey-12 dark:bg-grey-90"
              className="p-0 w-[135px]"
              options={defaultStationOptions}
              title={FIELD_NAMES.STATION}
            />
          </div>
        )}
        <div className="bg-white border border-1 mobile:border-none dark:border-grey-60 rounded-8 px-24 py-8 dark:bg-grey-90 mobile:space-y-[8px]">
          <h1 className="text-primary">{NOTIFICATION_MODAL_BODY.MESSAGE}</h1>
          <Title
            titleColorClass={titleColorClass}
            title={FIELD_NAMES.SUBJECT}
            className={titleClass}>
            <Input
              id={'notification-form-subtitle'}
              containerClassName={inputContainerClass}
              inputClassName={inputClass}
              maxLength={MAX_LENGTH_SUBJECT}
              defaultValue={notification?.subtitle}
            />
          </Title>
          <Title
            titleColorClass={titleColorClass}
            title={FIELD_NAMES.MESSAGE}
            className={titleClass}>
            <TextArea
              id={'notification-form-description'}
              containerClassName={inputContainerClass}
              areaClassName={inputClass}
              rows={2}
              maxLength={MAX_LENGTH_DESCRIPTION}
              defaultValue={notification?.description}
            />
          </Title>
        </div>
        <div className="bg-white border border-1 mobile:border-none dark:border-grey-60 rounded-8 px-24 py-8 dark:bg-grey-90">
          <h1 className="text-primary">{NOTIFICATION_MODAL_BODY.SCHEDULE}</h1>
          <div className="flex justify-between mobile:flex-wrap mobile:gap-y-8 mt-8 gap-x-8">
            <Title
              titleColorClass={titleColorClass}
              title={FIELD_NAMES.START}
              className="mobile:min-w-[140px] flex-1">
              <Datetime
                renderInput={(props) => (
                  <input {...props} readOnly value={formattedStartDate} />
                )}
                onChange={(value) => setStartDateValue(value.toString())}
                dateFormat={DATE_FORMAT}
                timeFormat={TIME_FORMAT}
                initialValue={formattedStartDate}
                className="rdtPickerOpenUpwardsRight"
                inputProps={{
                  className:
                    'border-1 border-black dark:border-grey-12 rounded-4 p-8 mt-8 font-body-bold text-primary text-14 dark:bg-grey-90 dark:text-grey-12 w-full',
                }}
              />
            </Title>
            <Title
              titleColorClass={titleColorClass}
              title={FIELD_NAMES.END}
              className="mobile:min-w-[140px] flex-1">
              <Datetime
                renderInput={(props) => (
                  <input {...props} readOnly value={formattedEndDate} />
                )}
                onChange={(value) => setEndDateValue(value.toString())}
                dateFormat={DATE_FORMAT}
                timeFormat={TIME_FORMAT}
                initialValue={formattedEndDate}
                className="rdtPickerOpenUpwardsLeft"
                inputProps={{
                  className:
                    'border-1 border-black dark:border-grey-12 font-body-bold text-primary text-14 rounded-4 p-8 mt-8 dark:bg-grey-90 dark:text-grey-12 w-full',
                }}
              />
            </Title>
          </div>
        </div>
      </div>
    </form>
  );
};

export default NotificationForm;
