import React, { Dispatch, useEffect } from 'react';
import moment from 'moment';
import Button from '../Button/Button';
import {
  DAY_PERIOD,
  PLUS_ONE,
  TIME_FORMAT_12,
  UTC_ZERO,
} from '../../utils/constants';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import { useSelector } from 'react-redux';
import {
  select24Format,
  selectUTC,
} from '../../redux/reducers/settingsReducer';
import { getCurrentTimeObject, getTimeObject } from '../../utils/helpers';

interface ITimePickerInput {
  currentState: ITimePicker;
  dispatch: Dispatch<any>;
  isOnETD?: boolean;
  calculatedDelay?: number;
  initialTime?: {
    hour: number | null;
    minutes: number | null;
    dayPeriod: string | null;
  };
  UTCDiff: string;
  disabledButtons?: boolean;
}

export interface ITimePicker {
  hour: number;
  minutes: number;
  dayPeriod: string;
  isDayPeriodActive: boolean;
  focusedInput: string;
  isInputActive: boolean;
  isTomorrow: boolean;
}

const ICON_SIZE = 18;

export const formatNumber = (number) => {
  return String(number).padStart(2, '0');
};

export const timePickerReducer = (state, action) => {
  switch (action.type) {
    case 'SET_TIME':
      return {
        ...state,
        hour: action.payload.hour,
        minutes: action.payload.minutes,
      };
    case 'SET_HOUR':
      return { ...state, hour: action.payload };
    case 'SET_MINUTES':
      return { ...state, minutes: action.payload };
    case 'INCREMENT_HOUR':
      return { ...state, hour: formatNumber(Number(state.hour) + 1) };
    case 'DECREMENT_HOUR':
      return { ...state, hour: formatNumber(Number(state.hour) - 1) };
    case 'INCREMENT_MINUTE':
      return { ...state, minutes: formatNumber(Number(state.minutes) + 1) };
    case 'DECREMENT_MINUTE':
      return { ...state, minutes: formatNumber(Number(state.minutes) - 1) };
    case 'SET_DAY_PERIOD':
      return { ...state, dayPeriod: action.payload };
    case 'SET_DAY_PERIOD_ACTIVE':
      return { ...state, isDayPeriodActive: action.payload };
    case 'FOCUS_INPUT':
      return { ...state, focusedInput: action.payload };
    case 'SET_INPUT_ACTIVE':
      return { ...state, isInputActive: action.payload };
    case 'SET_IS_TOMORROW':
      return { ...state, isTomorrow: action.payload };
    default:
      return state;
  }
};

const renderIcon = (icon) => {
  return (
    <Icon
      width={ICON_SIZE}
      height={ICON_SIZE}
      variant={icon}
      className="fill-white dark:fill-primary"
    />
  );
};

const maxMinutes = 59;
const meridiemChangePoint = '11';

const TimePickerInput = ({
  currentState: state,
  dispatch,
  isOnETD = false,
  calculatedDelay = 0,
  UTCDiff = UTC_ZERO,
  initialTime = { hour: null, minutes: null, dayPeriod: null },
  disabledButtons = false,
}: ITimePickerInput) => {
  const is24Format = useSelector(select24Format);
  const isUTC = useSelector(selectUTC);

  const maxHour = is24Format ? 23 : 12;
  const minHour = is24Format ? 0 : 1;
  const minHourString = is24Format ? '00' : '01';

  const isTomorrow = () => {
    const localTime = isOnETD
      ? getTimeObject(
          initialTime.hour ?? 0,
          initialTime.minutes ?? 0,
          initialTime.dayPeriod ?? DAY_PERIOD.AM,
          is24Format,
          isUTC,
          UTCDiff
        )
      : getCurrentTimeObject(isUTC, UTCDiff);

    const currentTime = is24Format
      ? moment({
          hour: parseInt(localTime.format(is24Format ? 'HH' : 'hh')),
          minute: parseInt(localTime.format('mm')),
        })
      : moment(
          `${parseInt(
            localTime.format(is24Format ? 'HH' : 'hh')
          )}:${localTime.format('mm A')}${UTCDiff}`,
          TIME_FORMAT_12
        );
    const midnightTomorrow = moment().endOf('day').add(1, 'day');
    const inputTime = is24Format
      ? moment({ hour: state.hour, minute: state.minutes })
      : moment(
          `${state.hour}:${state.minutes} ${state.dayPeriod}${UTCDiff}`,
          TIME_FORMAT_12
        );
    const isTomorrow =
      !inputTime.isBetween(currentTime, midnightTomorrow, 'minute') &&
      !inputTime.isSame(currentTime, 'minute');
    return isOnETD ? isTomorrow && !!calculatedDelay : isTomorrow;
  };

  const handleHourChange = (e) => {
    const value = e.target.value;
    if (value < 0 || value > maxHour) {
      return;
    }
    dispatch({ type: 'SET_HOUR', payload: value });
    dispatch({ type: 'SET_IS_TOMORROW', payload: isTomorrow() });
  };

  const handleMinuteChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0 && value <= maxMinutes) {
      dispatch({ type: 'SET_MINUTES', payload: value });
      dispatch({ type: 'SET_IS_TOMORROW', payload: isTomorrow() });
    }
  };

  const handleTimeChange = (action: string) => {
    if (state.focusedInput === 'hours') {
      if (action === 'ADD') {
        if (state.hour >= maxHour) {
          dispatch({ type: 'SET_HOUR', payload: minHourString });
          return;
        }
        dispatch({
          type: 'INCREMENT_HOUR',
        });
        if (state.hour.toString() === meridiemChangePoint) {
          state.dayPeriod === DAY_PERIOD.AM
            ? handleDayPeriod(DAY_PERIOD.PM)
            : handleDayPeriod(DAY_PERIOD.AM);
        }
        return;
      }
      if (state.hour <= minHour) {
        dispatch({ type: 'SET_HOUR', payload: maxHour.toString() });
        return;
      }
      dispatch({
        type: 'DECREMENT_HOUR',
      });
      if (!is24Format && state.hour.toString() === maxHour.toString()) {
        state.dayPeriod === DAY_PERIOD.PM
          ? handleDayPeriod(DAY_PERIOD.AM)
          : handleDayPeriod(DAY_PERIOD.PM);
      }
      return;
    }
    if (action === 'ADD') {
      if (state.minutes >= maxMinutes) {
        if (state.hour >= maxHour) {
          dispatch({ type: 'SET_HOUR', payload: minHourString });
        } else {
          dispatch({
            type: 'INCREMENT_HOUR',
          });
        }
        if (state.hour.toString() === meridiemChangePoint) {
          state.dayPeriod === DAY_PERIOD.AM
            ? handleDayPeriod(DAY_PERIOD.PM)
            : handleDayPeriod(DAY_PERIOD.AM);
        }
        dispatch({ type: 'SET_MINUTES', payload: '00' });
        return;
      }
      dispatch({
        type: 'INCREMENT_MINUTE',
      });
      return;
    }
    if (state.minutes <= 0) {
      if (state.hour <= minHour) {
        dispatch({ type: 'SET_HOUR', payload: maxHour.toString() });
      } else {
        dispatch({
          type: 'DECREMENT_HOUR',
        });
        if (!is24Format && state.hour.toString() === maxHour.toString()) {
          state.dayPeriod === DAY_PERIOD.PM
            ? handleDayPeriod(DAY_PERIOD.AM)
            : handleDayPeriod(DAY_PERIOD.PM);
        }
      }
      dispatch({ type: 'SET_MINUTES', payload: maxMinutes.toString() });
      return;
    }
    dispatch({
      type: 'DECREMENT_MINUTE',
    });
  };

  const handleFocus = (input) => {
    dispatch({ type: 'FOCUS_INPUT', payload: input });
    dispatch({ type: 'SET_INPUT_ACTIVE', payload: true });
    if (input === 'dayPeriod') {
      dispatch({ type: 'SET_DAY_PERIOD_ACTIVE', payload: true });
    }
  };

  const handleDayPeriod = (input) => {
    dispatch({ type: 'SET_DAY_PERIOD', payload: input });
  };

  const renderDayPeriodButton = (dayPeriod) => {
    return (
      <Button
        text={dayPeriod}
        textClassName={classNames(
          'text-12 font-body-text font-bold text-grey-40 dark:text-grey-40',
          {
            'text-primary dark:text-white': state.dayPeriod === dayPeriod,
          }
        )}
        onClick={() => handleDayPeriod(dayPeriod)}
      />
    );
  };

  useEffect(() => {
    dispatch({ type: 'SET_IS_TOMORROW', payload: isTomorrow() });
  }, [state.minutes, state.hour]);

  return (
    <div className="pt-32 pb-16">
      <div className="flex justify-evenly items-center">
        <Button
          Icon={renderIcon('minus')}
          className={classNames(
            'dark:bg-white h-40 w-40 rounded-full flex justify-center items-center',
            { 'bg-grey-12': disabledButtons, 'bg-primary': !disabledButtons }
          )}
          textClassName="leading-[32px] text-32 text-white dark:text-primary font-body-text"
          onClick={() => handleTimeChange('SUBTRACT')}
          disabled={disabledButtons}
        />
        <div className="relative flex justify-center items-center w-[184px] h-[40px] py-40 px-32 rounded-12 border-grey-12 border-1 text-56 text-primary dark:text-white dark:bg-grey-100 font-head-light text-center">
          <input
            type="number"
            className={classNames(
              'w-72 h-64 text-center dark:bg-grey-100 leading-none [-moz-appearance:textfield]',
              { 'bg-white': disabledButtons }
            )}
            value={state.hour}
            onChange={handleHourChange}
            onFocus={() => handleFocus('hours')}
            onBlur={() =>
              dispatch({ type: 'SET_HOUR', payload: formatNumber(state.hour) })
            }
            inputMode="numeric"
            disabled={disabledButtons}
          />
          :
          <input
            type="number"
            className={classNames(
              'w-72 h-64 text-center dark:bg-grey-100 leading-none [-moz-appearance:textfield]',
              { 'bg-white': disabledButtons }
            )}
            value={state.minutes}
            onChange={handleMinuteChange}
            onFocus={() => handleFocus('minutes')}
            onBlur={() =>
              dispatch({
                type: 'SET_MINUTES',
                payload: formatNumber(state.minutes),
              })
            }
            inputMode="numeric"
            disabled={disabledButtons}
          />
          {!!calculatedDelay && (
            <div
              className={classNames(
                'absolute -top-[20px] right-[10px] dark:text-red text-red text-12 font-body-text flex items-center justify-center',
                { 'right-[32px]': isTomorrow() }
              )}>
              ETD +{calculatedDelay} min
            </div>
          )}
          {isTomorrow() && (
            <div className="absolute -top-12 -right-12 font-body text-primary text-16 border-primary dark:border-grey-12 border-1 rounded-full h-32 w-32 bg-white flex items-center justify-center">
              {PLUS_ONE}
            </div>
          )}
        </div>
        <Button
          Icon={renderIcon('plus')}
          className={classNames(
            ' dark:bg-white h-40 w-40 rounded-full flex justify-center items-center',
            { 'bg-grey-12': disabledButtons, 'bg-primary': !disabledButtons }
          )}
          textClassName="leading-[32px] text-32 text-white dark:text-primary font-body-text"
          onClick={() => handleTimeChange('ADD')}
          disabled={disabledButtons}
        />
      </div>
      {!is24Format && (
        <div className="flex justify-center gap-10 pt-16">
          {renderDayPeriodButton(DAY_PERIOD.AM)}
          {renderDayPeriodButton(DAY_PERIOD.PM)}
        </div>
      )}
    </div>
  );
};

export default TimePickerInput;
