import React from 'react';
import classNames from 'classnames';
import Badge from '../../Badge/Badge';
import Icon from '../../Icon/Icon';
import PulseNotificationIcon from '../../PulseNotificationIcon/PulseNotificationIcon';
import { PhoneNumber } from '../../StationContact/Phone';
import Button from '../../Button/Button';
import { DEVICE_TYPE, FLIGHT_CHAT } from '../../../utils/constants';
import { IMessage } from '../FlightChat/FlightChatRoles';
import { useToggle } from '../../../utils/hooks/useToggle';
import { useDeviceBreakpoint } from '../../../utils/hooks/useDeviceBreakpoint';
import { callNumber } from '../../../utils/callNumber';

interface IChatItem {
  title: string;
  messagesCount?: number;
  isParticipantCheckedIn?: boolean;
  onChatSelect: (flight: string) => void;
  message?: IMessage;
  isActive: boolean;
  showPhone: boolean;
  toggle: () => void;
  phoneNumbers: string[];
  showPhoneIcon: boolean;
}

const ChatItem = ({
  title,
  messagesCount,
  isParticipantCheckedIn = false,
  onChatSelect,
  message,
  isActive = false,
  showPhone,
  toggle,
  phoneNumbers,
  showPhoneIcon = true,
}: IChatItem) => {
  const containerClassNames = classNames(
    'w-full mobile:p-24 py-56 pl-[16px] pr-32 dark:bg-grey-90 border-l-6 border-b-1 border-b-grey-12 flex flex-col dark:border-b-grey-60 mobile:border-l-0',
    {
      'border-secondary': !!messagesCount,
      'border-white dark:border-l-grey-90': !messagesCount,
      'bg-white': !isActive,
      'bg-grey-6': isActive,
    }
  );
  const showCheckedInNotification =
    process.env.REACT_APP_TAC_FEATURE_DISABLE_CHECKIN?.toLowerCase() !==
      'true' && isParticipantCheckedIn;

  const [showQR, setShowQR] = useToggle(false);

  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);

  const isOnlyOne = phoneNumbers.length <= 1;

  return (
    <button onClick={() => onChatSelect(title)} className={containerClassNames}>
      <div className="relative w-full flex items-center justify-between ">
        <div className="flex items-center justify-between mobile:w-full w-full">
          <div
            className={classNames(
              'whitespace-normal text-left font-head-bold text-15 text-primary dark:text-white flex items-center w-[270px] ',
              {
                'ml-24': !showCheckedInNotification,
                'ml-0': showCheckedInNotification,
              }
            )}>
            <PulseNotificationIcon
              className={classNames({
                'block mr-8': showCheckedInNotification,
                hidden: !showCheckedInNotification,
              })}
            />
            {title}
          </div>
          <div className="flex self-center">
            <div className="w-[42px]">
              {!!messagesCount && (
                <Badge
                  text={messagesCount}
                  className="bg-secondary w-full"
                  textClassName="text-primary dark:text-grey-90"
                />
              )}
            </div>
          </div>
        </div>
        {showPhoneIcon && (
          <Button
            Icon={
              <Icon
                variant="call"
                className="fill-primary dark:fill-white mobile:hidden ml-8"
              />
            }
            onClick={() =>
              callNumber(
                isOnlyOne,
                phoneNumbers?.[0],
                showQR,
                setShowQR,
                toggle,
                isMobile
              )
            }
          />
        )}
        {showPhone && (
          <ul className="w-full text-start absolute z-50 top-[30px] flex flex-col">
            {phoneNumbers.map((number) => (
              <li
                className={classNames(
                  'cursor-default flex justify-around font-head-light bg-white dark:bg-grey-90 text-primary dark:text-grey-12 text-16 text-center py-4 px-[8px] rounded-8 border-primary dark:border-grey-12 border-1 border-opacity-25',
                  { 'h-[0px] px-[0px] py-[0px] border-[0px]': isOnlyOne }
                )}>
                <PhoneNumber
                  key={number}
                  number={number}
                  isOnlyOne={isOnlyOne}
                  showQR={showQR}
                  setShowQR={setShowQR}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="w-full mt-32 tablet:hidden laptop:hidden desktop:hidden">
        {!!message?.text ? (
          <div className="w-full flex justify-between gap-10">
            <span className="text-14 font-body-text text-grey-60 text-ellipsis overflow-hidden whitespace-nowrap">
              {`${message.role}: ${message.text}`}
            </span>
            {message?.time && (
              <span className="text-14 font-body-text text-grey-40">
                {message.time}
              </span>
            )}
          </div>
        ) : (
          <div className="text-14 font-body-text text-grey-25 flex self-start">
            {FLIGHT_CHAT.START_CONVERSATION}
          </div>
        )}
      </div>
    </button>
  );
};

export default ChatItem;
