import { useMutation } from '@apollo/client';
import { Mutation, UserNotification } from '../generated/graphql';
import { IMutationResponse } from '../types';
import { MARK_NOTIFICATIONS_AS_READ } from '../../graphql/notifications/notifications.mutations';
import { useDispatch } from 'react-redux';
import { addError } from '../../redux/reducers/notificationReducer';

interface MarkAsReadResponse extends IMutationResponse<UserNotification[]> {
  markAsRead: (notificationIds: string[]) => Promise<UserNotification[]>;
}

export const useMarkNotificationAsRead = (): MarkAsReadResponse => {
  const [onMarkAsRead, { data, error }] = useMutation<
    Pick<Mutation, 'markUserNotificationsAsRead'>
  >(MARK_NOTIFICATIONS_AS_READ);

  const dispatch = useDispatch();

  const markAsRead = async (notificationIds: string[]) => {
    try {
      const response = await onMarkAsRead({
        variables: {
          notifications: {
            updateBy: 'NOTIFICATION',
            ids: notificationIds,
          },
        },
      });
      if (response?.data) {
        return response.data.markUserNotificationsAsRead?.data ?? [];
      }

      dispatch(
        addError(
          response?.data?.markUserNotificationsAsRead?.error?.message ??
            'Could not update notifications!'
        )
      );

      return [];
    } catch (error) {
      return [];
    }
  };
  return {
    markAsRead,
    data: data?.markUserNotificationsAsRead?.data ?? [],
    error: error,
  };
};
