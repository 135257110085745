import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from 'react-redux';
import store from './redux/store';
// import reportWebVitals from './utils/reportWebVitals';
import './globals.scss';
import App from './App';
import { checkCUTEDevice } from './utils/helpers';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

serviceWorkerRegistration.register();
const isCUTEDevice = checkCUTEDevice();
try {
  const appInsights = new ApplicationInsights({
    config: {
      connectionString:
        process.env.REACT_APP_TAC_APPINSIGHTS_CONNECTION_STRING_KV ?? '',
      correlationHeaderDomains: [
        process.env.REACT_APP_TAC_APPINSIGHTS_BE_URL ?? '',
      ],
      enableCorsCorrelation: true,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });

  if (
    !isCUTEDevice &&
    process?.env?.REACT_APP_TAC_APPINSIGHTS_FEATURE_DISABLED?.toUpperCase() !==
      'TRUE'
  ) {
    appInsights.loadAppInsights();
  }
} catch (err) {
  console.error(err);
}

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// if (process.env.NODE_ENV === 'development') {
//   reportWebVitals(console.log);
// }
